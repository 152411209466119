import axios from "axios";

export default {
  namespaced: true,
  state: {
    form: {},
    details: {
      header: {},
      member: {},
      barang: [],
      payment: [],
      price: {},
      shipping: {},
    },
    formPembatalan: {},
    level: 0,
  },
  mutations: {
    setLevel(state, value) {
      state.level = value;
    },
    setForm(state, value) {
      state.form = value;
    },
    setDetails(state, value) {
      state.details.header = value.header;
      state.details.member = value.member;
      state.details.barang = value.details;
      state.details.payment = value.payment;
      state.details.price = value.price;
      state.details.shipping = value.shipping;
    },
    setFormPembatalan(state, value) {
      state.formPembatalan, value;
    },
  },
  actions: {
    reset({ state, rootState }) {
      if (
        rootState.authentication.user.outlet == "5000" &&
        rootState.authentication.user.level == 1
      ) {
        state.form = {};
      } else {
        state.form = {
          outlet: rootState.authentication.user.outlet,
        };
      }
      state.formPembatalan = {
        option: 3,
      };
      state.details.header = {};
      state.details.member = {};
      state.details.barang = [];
      state.details.payment = [];
      state.details.price = {};
      state.details.shipping = {};
    },
    showReceiptDetail({ commit, state }) {
      const search = {
        outlet: state.form.outlet,
        receipt: state.form.receipt,
      };
      commit("alert/setLoading", true, { root: true });
      axios
        .post("canceled/show-transaction", search)
        .then((res) => {
          commit("alert/setLoading", false, { root: true });
          commit("setDetails", res.data.data);
        })
        .catch((err) => {
          commit("alert/setLoading", false, { root: true });
          commit("setDetails", err.response.data.data);
        });
    },
    saveCanceled({ commit, state, dispatch, rootState }) {
      const test = {
        form: state.formPembatalan,
        receipt: state.details.header.receipt,
        outlet: state.details.header.outlet,
        iduser: rootState.authentication.user.id,
      };
      commit("alert/setLoading", true, { root: true });
      axios
        .post("canceled/save-transaction", test)
        .then((res) => {
          commit("alert/setLoading", false, { root: true });
          commit("alert/setAlertSuccess", res.data, { root: true });
          dispatch("alert/removeAlertSuccess", 1, { root: true });
          dispatch("reset");
        })
        .catch((err) => {
          commit("alert/setLoading", false, { root: true });
          commit("alert/setAlertError", err.response.data, { root: true });
          dispatch("alert/removeAlertError", 0, { root: true });
        });
    },
  },
  getters: {
    filterOutlet(state) {
      const level = [1];
      return level.includes(state.level);
    },
  },
};
